<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-alert
          color="red"
          icon="mdi-account-question-outline"
          class="title"
          border="bottom"
          colored-border
        >
          Проверка знаний по теме: "Меры ПБ в зданиях и помещениях с массовым скополением людей"
        </v-alert>
      </v-col>
      <v-col>
        <v-card-title v-if="resultQuiz4 === null" class="font-weight-light pl-0">
          <div v-if="questionIndex < allQuestionsQuiz4.length">
             Вопрос {{ questionIndex+1 }} из {{ allQuestionsQuiz4.length }}
          </div>
          <div v-if="questionIndex > allQuestionsQuiz4.length">
          </div>

        </v-card-title>
      </v-col>
      <v-col cols="12">
        <!-- <p>{{ quiz || 'null' }}</p> -->
        <div v-if="resultQuiz4 === null">
          <v-card flat
          class=""
          v-for="(questions, index) in allQuestionsQuiz4"
          :key="index"
          >
          <div v-show="index === questionIndex">
            <h3 class="mb-5">{{ questions.text }}</h3>
            <!-- <v-alert type="success">
              Верно!
            </v-alert> -->
              <v-radio-group
                class="mt-10 d-flex align-end"
                v-model="quiz[index]"
                :disabled="!enabled"
                hint

              >
                <v-radio
                  v-for="(answer, i) in questions.answer"
                  :key="i"
                  :name="`answer ${index}`"
                  :label="answer.text"
                  :value="answer.correct"
                  color="red"
                  on-icon="mdi-check"
                  class="mb-7 d-flex align-start"

                ></v-radio>
              </v-radio-group>

          <div v-if="alert == false">
            <v-alert
              prominent
              type="warning"
              outlined
              color="red"
            >
              <v-row align="center">
                <v-col class="grow title">
                  Выберите вариант ответа
                </v-col>
                <v-col class="shrink">
                  <div v-if="quiz[index] != null">
                    <v-btn
                      color="red"
                      dark
                      @click="valid">

                      Ответить
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      color="red"
                      dark
                    >
                      Ответить
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-alert>
          </div>
          </div>

          </v-card>

           <div v-if="quiz[questionIndex] >= 0 && alert == true">
             <div v-if="quiz[questionIndex] === true">
                <v-alert
                  prominent
                  type="success"
                >
                  <v-row align="center">
                    <v-col class="grow title">
                      Правильно! Вы выбрали верный ответ!
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                      color="green lighten-2"
                      @click="next">Следующий вопрос</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
              <div v-else>
                <v-alert
                  prominent
                  type="error"
                >
                  <v-row align="center">
                    <v-col class="grow title">
                      Неправильно, Вы выбрали неверный ответ!
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                      color="red lighten-2"
                      dark
                      @click="next">Следующий вопрос</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
          </div>
        </div>
        <div v-else>
          <div>
         <!-- <div> -->
          <v-card class="d-flex flex-column align-center justify-center pa-10 mb-10">
            <v-icon
              size="50"
              color="green"

            >
              mdi-check
            </v-icon>
             <h2>
              Тест завершен
            </h2>
            <v-card-title>
              Вы ответили верно на: {{ resultQuiz4 }} из {{ allQuestionsQuiz4.length }} вопросов
            </v-card-title>
          </v-card>
          <v-sheet flat class="mt-10 d-flex align-center justify-space-around">
            <v-btn
              color="red"
              dark
              height="50"
              @click="reloadPage"
              >
                <v-icon class="mr-5">mdi-selection-ellipse-arrow-inside</v-icon>
                Пройти тест еще раз
              </v-btn>
              <v-btn
              color="green"
              dark
              height="50"
              to="/workshop2-1"
              @click="setResQuiz"
              >
                Перейти практическому заданию
                <v-icon class="ml-5">mdi-arrow-right-circle-outline</v-icon>
              </v-btn>
          </v-sheet>
        </div>
        </div>
        <div v-show="questionIndex === allQuestionsQuiz4.length">
        <div v-if="resScore() < grade">
          <v-card class="d-flex flex-column align-center justify-center pa-10 mb-10">
            <v-icon
              size="50"
              color="red"

            >
              mdi-close
            </v-icon>
             <h2>
              Тест не пройден
            </h2>

            <v-card-title>
              Вы ответили верно на: {{ score() }} из {{ allQuestionsQuiz4.length }} вопросов.
            </v-card-title>
            <v-card-title>
              Для прохождения теста необходимо правильно ответить минимум на {{ gradeScore() }} из {{ allQuestionsQuiz4.length }} вопросов.
            </v-card-title>
          </v-card>
        </div>
        <div v-else>
          <v-card class="d-flex flex-column align-center justify-center pa-10 mb-10">
            <v-icon
              size="50"
              color="green"

            >
              mdi-check
            </v-icon>
             <h2>
              Тест пройден
            </h2>

            <v-card-title>
              Вы ответили верно на: {{ score() }} из {{ allQuestionsQuiz4.length }} вопросов.
            </v-card-title>
          </v-card>
        </div>

          <v-sheet flat class="mt-10 d-flex align-center justify-space-around">
            <div v-if="resScore() < grade">
              <v-btn
                color="red"
                dark
                height="50"
                @click="reloadPage"
                >
                  <v-icon class="mr-5">mdi-selection-ellipse-arrow-inside</v-icon>
                  Повторить попытку
                </v-btn>
            </div>
            <div v-if="resScore() >= grade">
              <v-btn
              color="green"
              dark
              height="50"
              to="/workshop2-1"
              @click="setResQuiz"
              >
                Перейти к практическому заданию
                <v-icon class="ml-5">mdi-arrow-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-sheet>
        </div>
        <div v-show="quiz[questionIndex] === ''"></div>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'allQuestionsQuiz4',
      'questionsCount'
    ])
  },
  data: () => ({
    quiz: [],
    questionIndex: 0,
    dialog: false,
    alert: false,
    enabled: true,
    resultQuiz4: null,
    grade: 70
  }),
  methods: {
    prev: function () {
      this.questionIndex--
    },
    next: function () {
      this.questionIndex++
      this.alert = false
      this.enabled = true
    },
    score: function () {
      return this.quiz.filter(function (val) { return val === true }).length
    },
    valid: function () {
      this.alert = true
      this.enabled = false
    },
    reloadPage: function () {
      this.$router.go(this.$router.currentRoute)
      localStorage.removeItem('resultQuiz4')
    },
    setResQuiz: function () {
      localStorage.resultQuiz4 = this.score()
      this.resultQuiz4 = localStorage.resultQuiz4
    },
    resScore: function () {
      const currScore = this.quiz.filter(function (val) { return val === true }).length
      const scoreQuestions = this.allQuestionsQuiz4.length
      return currScore * 100 / scoreQuestions
    },
    gradeScore: function () {
      const scoreQuestions = this.allQuestionsQuiz4.length
      return Math.floor(this.grade * scoreQuestions / 100)
    }
  },
  mounted () {
    if (localStorage.resultQuiz4) {
      this.resultQuiz4 = localStorage.resultQuiz4
    }
  }
}
</script>
